<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-row class="content-box">
      <!-- <el-row>
        <div class="sec-title">教练科目分布</div>
        <all-echart :chartType="'ring'" ref="chart"></all-echart>
      </el-row> -->
      <el-row class="main" style="margin-bottom: 1rem">
        <div class="sec-title">教练统计</div>
        <div class="newCouch" style="float: right">
          <el-button @click="addVisible = true">新增</el-button>
        </div>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="姓名">
            <el-input v-model="Couch_name"></el-input>
          </el-form-item>
          <el-form-item label="教龄">
            <el-input v-model="Couch_age"></el-input>
          </el-form-item>
          <el-form-item label="所属机构">
            <el-select filterable v-model="mechanism_id" clearable>
              <el-option
                v-for="(option, index) in mechanism_name_list"
                :key="index"
                :value="option.id"
                :label="option.mechanism_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" >
            <el-select v-model="categories" style="width:100px;">
              <el-option label="机构老师" value="教练"></el-option>
              <el-option label="私教" value="私教"></el-option>
              <el-option label="陪练" value="陪练"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="status" style="width:100px;">
              <el-option label="已通过" :value="2"></el-option>
              <el-option label="待审核" :value="1"></el-option>
              <el-option label="审核未通过" :value="3"></el-option>
              <el-option label="注销" :value="4"></el-option>
              <el-option label="已冻结" :value="5"></el-option>
              <el-option label="未启用" :value="6"></el-option>
            </el-select>
            <el-button @click="search()">查询</el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="search">
          <label for="Couch_name">姓名</label>
          <el-input v-model="Couch_name" clearable></el-input>
          <label for="Couch_age">教龄</label>
          <el-input v-model="Couch_age" placeholder="" clearable></el-input>
          <label for="mechanism_name">所属机构</label>
          <el-select filterable v-model="mechanism_id" clearable>
            <el-option v-for="(option, index) in mechanism_name_list" :key="index" :value="option.id"
              :label="option.mechanism_name"></el-option>
          </el-select>
          <label for="mechanism_name">类型</label>
          <el-select v-model="categories">
            <el-option label="机构老师" value="教练"></el-option>
            <el-option label="私教" value="私教"></el-option>
            <el-option label="陪练" value="陪练"></el-option>
          </el-select>
          <label for="mechanism_name">状态</label>
          <el-select v-model="status">
            <el-option label="已通过" :value="2"></el-option>
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="审核未通过" :value="3"></el-option>
            <el-option label="注销" :value="4"></el-option>
            <el-option label="已冻结" :value="5"></el-option>
            <el-option label="未启用" :value="6"></el-option>
          </el-select>
          <el-button @click="search()">查询</el-button>
        </div> -->
      </el-row>
      <el-row>
        <el-table :data="couchList" v-loading="is_loading">
          <el-table-column label="姓名" prop="full_name"></el-table-column>
          <el-table-column label="头像" prop="">
            <template slot-scope="scope">
              <el-image style="height: 5rem" :src="scope.row.photo" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="教龄" prop="master_age"></el-table-column>
          <el-table-column
            label="所属机构"
            prop="map.mechanismEntity.mechanism_name"
          ></el-table-column>
          <el-table-column label="身份" prop="categories"></el-table-column>
          <el-table-column label="联系方式" prop="mobile"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: orange"
                >正在审核</span
              >
              <span v-if="scope.row.status == 2" style="color: green"
                >审核通过</span
              >
              <span v-if="scope.row.status == 3" style="color: red"
                >审核拒绝</span
              >
              <span v-if="scope.row.status == 4" style="color: grey">注销</span>
              <span v-if="scope.row.status == 5" style="color: red">冻结</span>
              <span v-if="scope.row.status == 6">未启用</span>
            </template>
          </el-table-column>
          <el-table-column label="住址" prop="address"></el-table-column>
          <el-table-column label="操作" prop="" width="280">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="primary"
                @click="toCouchDetails(scope.row)"
                >查看详情</el-button
              >
              <el-button
                size="small"
                type="warning"
                @click="openUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="frozen(scope.row)"
                v-if="scope.row.status == 2"
                >冻结
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="couchTotal"
        ></paging-fy>
      </el-row>
    </el-row>
    <!-- 教练新增 -->
    <el-dialog
      :width="ks ? '95%' : '30%'"
      :visible="addVisible || updateVisible"
      :title="
        addVisible ? '新增教练/陪练/老师' : updateVisible ? '修改信息' : ''
      "
      :before-close="handleClose"
    >
      <el-form label-width="auto" v-model="form">
        <el-form-item label="姓名" required>
          <el-input
            v-model="form.nick_name"
            :placeholder="temp.full_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="住址">
          <el-input
            v-model="form.address"
            :placeholder="temp.address"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" required>
          <el-radio v-model="form.sex" :label="1">男</el-radio>
          <el-radio v-model="form.sex" :label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="头像" required>
          <avatar-uploader
            @getImgUrl="getCover"
            :url="temp.avatar"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="身份证" required>
          <el-input
            v-model="form.id_card"
            :placeholder="temp.id_card"
          ></el-input>
        </el-form-item>

        <el-form-item label="身份" required>
          <el-select
            v-model="form.categories"
            remote
            @focus.once="getCouchType"
          >
            <el-option
              v-for="(item, index) in couch_type_list"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="教练资格/教师证"
          required
          :if_clear="if_clear"
          v-if="form.categories == '私教' || form.categories == '教练'"
        >
          <avatar-uploader
            @getImgUrl="getPhoto"
            :url="temp.photo"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item
          label="服务距离"
          required
          v-if="form.categories == '私教' || form.categories == '陪练'"
        >
          <el-input v-model="form.serve_range"></el-input>
        </el-form-item>
        <el-form-item
          label="绑定场馆"
          required
          :if_clear="if_clear"
          v-if="form.categories == '私教'"
        >
          <async-transform @confirm="getPlaceIds"></async-transform>
        </el-form-item>
        <el-form-item label="教龄" required>
          <el-input
            v-model="form.master_age"
            :placeholder="temp.master_age"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" required>
          <el-input v-model="form.mobile" :placeholder="temp.mobile"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" required>
          <el-input
            v-model="form.login_pass"
            :placeholder="temp.login_pass"
          ></el-input>
        </el-form-item>
        <el-form-item label="个人简介">
          <el-input
            v-model="form.introduction_content"
            :placeholder="temp.introduction_content"
          ></el-input>
        </el-form-item>
        <el-form-item label="荣誉资质">
          <el-input
            v-model="form.introduction"
            :placeholder="temp.introduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="个人照片" required="">
          <images-uploader
            :getImgUrl="getImgsUrl"
            :urls="temp.introduction_cover"
          ></images-uploader>
        </el-form-item>
        <el-form-item
          label="所属机构"
          required=""
          v-if="form.categories == '私教' || form.categories == '教练'"
        >
          <el-select
            v-model="form.mechanism_id"
            filterable
            remote
            :remote-method="getMecList"
            @focus.once="getMecList"
            :placeholder="getMecName(temp.mechanism_id).mechanism_name"
          >
            <el-option
              v-for="(item, index) in mechanism_name_list"
              :key="index"
              :label="item.mechanism_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教学科目" required>
          <el-select
            v-model="form.categories_child"
            remote
            @focus.once="getCateList"
            :placeholder="temp.categories_child"
          >
            <el-option
              v-for="(item, index) in categories_child_list"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addVisible" @click="addNewCouch()"
          >添 加</el-button
        >
        <el-button type="primary" v-if="updateVisible" @click="update()"
          >修改</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImagesUploader from "../../components/imagesUploader.vue";
import asyncTransform from "../../components/asyncTransform.vue";
export default {
  components: {
    ImagesUploader,
    asyncTransform,
  },
  data() {
    return {
      ks: "",
      nav: { firstNav: "机构管理中心", secondNav: "教练管理" },
      is_loading: true,
      Couch_name: "",
      place_ids: [],
      Couch_age: "",
      categories: null,
      mechanism_id: "",
      mechanism_name_list: [],
      couchList: [],
      couchTotal: 0,
      currentPage: 1,
      addVisible: false,
      placeList: [],
      temp: {},
      status: null,
      updateVisible: false,
      if_clear: false,
      couch_type_list: [],
      categories_child_list: [],
      form: {
        register_platform: "体育宝",
        type: "教练",
      },
      place_ids: "",
    };
  },

  methods: {
    handleCurrentChange(val) {
      // console.log(val);
      this.is_loading = true;
      this.currentPage = val;
      this.getCouchList();
    },
    getPlaceIds(v) {
      this.place_ids = v;
      console.log(v);
    },
    getPlaceList() {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: this.status ? this.status : null,
            type: "场馆",
            categories: "体育运动",
            source: "体育宝",
            pageSize: 10,
            // is_school: false,
            currentPage: this.currentPage,
            // master_id: this.master_id? this.master_id:null,
          },
        })
        .then((res) => {
          this.placeList = res.data.data.rows;
        });
    },
    getCouchList() {

      this.is_loading = true;
      let url = "/user/masterInfo/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,

            // source:'教练',
            categories: this.categories,
            type: "教练",
            status: this.status ? this.status : null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
            full_name: this.Couch_name ? this.Couch_name : null,
            master_age: this.Couch_age ? this.Couch_age : null,
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.couchList = res.data.data.rows;
          this.couchTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: "体育宝",
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;

          // this.typeTotal = res.data.data.total;
        });
    },
    getMecName(id) {
      let a = this.mechanism_name_list.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a;
      } else return {};
    },
    getMecList(query) {
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: "teach_paypal",
            // is_cooperation: true,
            pageSize: 10,
            status: 2,
            mechanism_name: typeof query == "string" ? query : null,
            categories: "体育运动",
            // source:'体育宝',
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.mechanism_name_list = res.data.data.rows;
        })
        .catch((rej) => {});
    },
    getCouchType() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: "教练",
          },
        })
        .then((res) => {
          this.couch_type_list = res.data.data;

          // this.typeTotal = res.data.data.total;
        });
    },
    // getOrgCouchType() {
    //   let url = "/user/mastermechanism/queryMasterInSchoolCount";
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         is_school: false,
    //       },
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       let temp = [];
    //       for (let key in res.data.data) {
    //         if (key != "total") {
    //           temp.push({
    //             name: key,
    //             value: res.data.data[key],
    //           });
    //         }
    //       }
    //       this.$refs.chart.drawRing({ data: temp, total: res.data.data.total });
    //     });
    // },
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            mechanism_name: query,
            pageSize: 10,
            currentPage: 1,
            status: 2,
            source: "体育宝",
          },
        })
        .then((res) => {
          this.mechanism_name_list = res.data.data;
        });
    },
    search() {
      this.is_loading = true;
      this.getCouchList();
    },
    frozen(row) {
      let url = "/user/masterInfo/updateMechanismMasterInfo";
      this.$axios
        .post(url, {
          id: row.id,
          status: 5,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "操作成功!" });
            this.getCouchList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    toCouchDetails(row) {
      this.$router.push({
        name: "couchDetails",
        query: {
          type: "mec",
          couchID: row.id,
          couchMec: row.map.mechanismEntity.mechanism_name,
        },
      });
    },
    openAddDialog() {
      this.addCouchVisible = true;
    },
    getPhoto(v) {
      this.form.photo = v;
    },
    getCover(v) {
      this.form.avatar = v;
      console.log(this.form);
    },
    // hide(cancel) {
    //   if (cancel !== false) {
    //     this.$emit('update:visible', false);
    //     this.$emit('close');
    //     this.closed = true;
    //   }
    // },
    addNewCouch() {
      let url = "/user/user/insertMaster";
      let url2 = "/user/masterSetPricemaster/insertBindList";
      this.form.login_name = this.form.mobile;
      let data = { ...this.form };
      data.status = 1;
      console.log(data);
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "添加成功!" });
          this.addVisible = false;
          console.log(res);
          if (this.place_ids) {
            this.$axios
              .post(url2, {
                type: this.form.categories,
                ids: this.place_ids,
                master_id: res.data.data,
              })
              .then((res) => {
                console.log(res);
              });
          }
          this.form = {
            register_platform: "体育宝",
            type: "教练",
          };

          this.getCouchList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    openUpdate(row) {
      let url = "/user/masterSetPricemaster/queryManagerListPage";

      this.form = {};
      this.temp = row;
      this.$axios
        .get(url, {
          master_id: this.temp.id,
        })
        .then((res) => {
          console.log(res);
        });
      this.$set(this.form, "sex", this.temp.sex);
      this.$set(this.form, "categories", this.temp.categories);
      this.updateVisible = true;
    },
    update() {
      let url = "/user/masterInfo/updateMechanismMasterInfo";
      let url2 = "/user/masterSetPricemaster/update";
      let data = { ...this.form };
      console.log(data);
      data.id = this.temp.id;
      if (this.place_ids) {
        this.$axios
          .post(url2, {
            type: this.form.categories,
            ids: this.place_ids,
            master_id: res.data.data,
          })
          .then((res) => {
            console.log(res);
          });
      }
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "修改成功!" });
          this.updateVisible = false;
          this.form = {
            register_platform: "体育宝",
            type: "教练",
          };
          this.getCouchList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    handleClose(done, callback) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.if_clear = true;
          this.addVisible = false;
          this.updateVisible = false;
          this.form = {
            register_platform: "体育宝",
            type: "教练",
          };
          this.temp = {};
          // if (typeof done == 'function') {
          done();
          // }else
        })
        .catch((_) => {
          // console.log(_)
        });
    },
    getImgsUrl(v) {
      this.form.introduction_cover = v;
    },
  },
  mounted() {
    this.getCouchList();
    this.getMecList();
    this.ks = window.screen.width < 768;
    // this.getOrgCouchType();
  },
};
</script>

<style>
.search > *:not(:last-child) {
  margin-right: 1rem;
}
.main .el-input {
  width: inherit;
}
.el-table thead tr th {
  background-color: #eaeaea !important;
}
</style>
