<template>
  <div>
    <el-popover :title="binding_venues_id?'绑定场馆商品':'添加场馆'" v-model="show" >
      <el-row>
        <label for="">搜索</label>
        <el-input clearable placeholder="输入名称" v-model="query"></el-input>
        <el-button @click="search()">搜索</el-button>
        <el-button @click="confirm()" type="primary" style="float:right">确认</el-button>
      </el-row>
      <el-checkbox-group v-model="place_ids" @change="selectPlace">
        <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto" infinite-scroll-immediate>
          <li v-for="item in placeList" class="infinite-list-item" :key="item.id">
            <el-checkbox :label="item.id">
              {{ item.title }}
            </el-checkbox>
          </li>
        </ul>
      </el-checkbox-group>
      <el-button slot="reference">{{content || (Object.keys(temp).length?(temp.bind_id?'重新绑定':'未绑定'):'绑定课程')}}</el-button>
    </el-popover>

  </div>
</template>

<script>
export default {
  props: {
    binding_venues_id: {
      type: Number,
      default: null,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    temp: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      placeList: [],
      place_ids: [],
      query: null,
      show: false,
      currentPage: 1,
      content: '',
    }
  },
  mounted() {
    this.getPlaceList()
  },
  watch: {
    temp:{
      handler(newV,oldV){
      if(newV.bind_id){
        this.place_ids=[...newV.bind_id.split(',')]
      }else{
        this.place_ids = []
      }
    },deep:true,
  immediate:true},
    clear(newV, oldV) {
      console.log(newV);
      if (newV) {
        this.place_ids = []
        this.content = ''
      }
    },
  },
  computed: {
    ids() {
      if (typeof this.temp.bind_id == 'string') {
        return this.temp.bind_id.split()
      } else {
        return '暂未绑定服务'
      }
    },
  },
  methods: {
    selectPlace(v) {
      console.log(v)
      let temp = []
      this.place_ids.forEach(value=>{
        let a = this.placeList.find(item=>{
          return value == item.id
        })
        if(a){
          temp.push(a.title)
        }
      })
      this.content = temp.join(',')
    },
    getTemp() {
      return this.temp
    },
    getCourseName(ids) {},
    confirm() {
      this.$emit('confirm', this.place_ids.join(','))
      this.show = false
    },
    load() {
      this.currentPage++
      this.getPlaceList()
    },
    search() {
      this.getPlaceList()
    },
    getPlaceList() {
      let url = '/user/masterSetPrice/queryByMessage'
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: '场馆',
            categories: '体育运动',
            source: '体育宝',
            pageSize: 10,
            title: this.query ? this.query : null,
            binding_venues_id: this.binding_venues_id ? this.binding_venues_id : null,
            // is_school: false,
            currentPage: this.currentPage,
            // master_id: this.master_id? this.master_id:null,
          },
        })
        .then((res) => {
          res.data.data.rows.forEach(item=> item.id = item.id+'')
          this.placeList = res.data.data.rows
          // this.placeList = this.placeList.concat(res.data.data.rows)
        })
    },
  },
}
</script>

<style>
</style>